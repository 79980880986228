<template>
  <v-container
    fluid
    class="light-blue lighten-5 pb-16"
  >
    <v-row>
      <v-col
        sm="12"
        md="12"
        lg="12"
      >
        <base-material-card
          color="primary"
          icon="mdi-group"
          inline
        >
          <v-row>
            <v-col
              cols="12"
              align="right"
            >
              <v-btn
                color="primary"
                @click="generateFile()"
              >
                Generate
              </v-btn>
            </v-col>
          </v-row>
          <v-data-table
            :items="data"
            sort-by="created"
            class="elevation-1"
            :sort-desc="[true]"
            :headers="headers"
          >
            <template v-slot:item.name="{item}">
              <a
                :href="item.downloadURL"
                download=""
              >{{ getName(item.name) }}</a>
            </template>
            <template v-slot:item.downloadURL="{item}">
              <v-btn
                color="primary"
                :href="item.downloadURL"
                download
              >
                <v-icon left>
                  mdi-download
                </v-icon>
                Download
              </v-btn>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
    <v-overlay v-if="loading">
      <v-row
        class="mt-12"
      >
        <v-col class="d-flex justify-center">
          <ellipse-progress
            :loading="loading"
            :size="180"
            img-src="src/assets/images/termlife-icon.png"
            thickness="5"
            empty-thickness="3"
            line-mode="out 5"
            legend="false"
            animation="rs 700 1000"
            font-size="1.5rem"
            color="#00A1B7"
            empty-color-fill="transparent"
            filename="termlife-icon.png"
          >
          </ellipse-progress>
        </v-col>
      </v-row>
    </v-overlay>
    <v-dialog
      v-model="successDialog"
      theme="light"
      max-width="700"
      color="white"
      persistent
    >
      <v-card
        style="background: #fff"
        theme="light"
        min-height="20vh"
      >
        <v-card-text class="justify-center text-center">
          <v-row>
            <v-col
              class="successMessage"
              data-testid="successMessage"
            >
              Report Generated Successfully!
            </v-col>
          </v-row>
        </v-card-text>
        <v-row no-gutters>
          <v-col>
            <div class="d-flex justify-center">
              <v-btn
                color="primary"
                class="mb-4 mt-4 font-weight-bold"
                data-testid="globalErrorCloseBtn"
                @click="()=>{this.successDialog=false}"
              >
                {{ $t('close') }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import firebase from 'firebase/app'
  import 'firebase/functions'

  export default {
    data () {
      return {
        loading: false,
        successDialog: false,
        data: [
        ],
      }
    },
    computed: {
      headers () {
        return [
          { text: 'Created', value: 'created' },
          { text: 'Name', value: 'name' },
          { text: 'Download URL', value: 'downloadURL' },
        ]
      },
    },
    async created () {
      await this.getFiles()
    },
    methods: {
      getName (path) {
        return path.split('/').at(-1).split('_').join(' ')
      },
      async generateFile () {
        try {
          this.loading = true
          const response = await firebase
            .functions()
            .httpsCallable('CATermGenerateHumaniaFeed')({})
          this.successDialog = true
          await this.getFiles()
        } catch (e) {
          this.$store.commit('setError', e.message || e)
        } finally {
          this.loading = false
        }
      },
      async getFiles () {
        try {
          this.loading = true
          const { data: { response } } = await firebase
            .functions()
            .httpsCallable('CATermFBFiles')({})
          this.data = response
        } catch (e) {
          this.$store.commit('setError', e.message || e)
        } finally {
          this.loading = false
        }
      },
    },
  }
</script>

<style scoped>
.style-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 300;
}
::v-deep .v-data-table thead tr th {
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  letter-spacing: 0.4px !important;
  color: #757575 !important;
}
.style-modal {
  height: 200px;
}
</style>
