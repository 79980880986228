<template>
  <v-stepper-content
    step="7"
    :class="parent.mobileDevice ? 'pa-0' : ''"
  >
    <div
      class="text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text"
    >
      {{ $t('caTerm.payments.payment') }}
    </div>
    <v-row class="d-flex justify-center">
      <v-col class="d-flex justify-center billing-container-checkbox">
        <v-checkbox
          v-model="billingCheckbox"
          :label="$t('caTerm.payments.billingSameAsMailing')"
          @click="
            clickedFieldGlobal(
              'billingInfoChckbx',
              83,
              'CA Term Survey v1'
            )
          "
        >
        </v-checkbox>
      </v-col>
    </v-row>
    <!----------------------Billing Address--------------------->
    <div
      v-if="!billingCheckbox"
      class="billing-container"
    >
      <ValidationObserver ref="billingInfoObserver">
        <v-form
          ref="billingForm"
        >
          <v-row>
            <v-col>
              <h1 class="primary--text ml-3 mt-2">
                {{ $t('caTerm.payments.billingInformation') }}
              </h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="11"
              sm="8"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                name="First Name"
                :rules="{
                  required: true,
                  validi18nName:true,
                  max: 20,
                }"
              >
                <v-text-field
                  v-model="parent.billingFirstName"
                  outlined
                  :label="$t('caTerm.basicInfo.firstName')"
                  :disabled="billingCheckbox"
                  :error-messages="errors"
                  data-testid="billing-firstname"
                  @keyup="validateField"
                  @click="
                    clickedFieldGlobal(
                      'billingFirstName',
                      84,
                      'CA Term Survey v1'
                    )
                  "
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col
              cols="11"
              sm="8"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Last Name"
                :rules="{
                  required: true,
                  validi18nName:true,
                }"
              >
                <v-text-field
                  v-model="parent.billingLastName"
                  outlined
                  :label="$t('caTerm.basicInfo.lastName')"
                  :disabled="billingCheckbox"
                  :error-messages="errors"
                  data-testid="billing-lastname"
                  @keyup="validateField"
                  @click="
                    clickedFieldGlobal(
                      'billingLastName',
                      85,
                      'CA Term Survey v1'
                    )
                  "
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="11"
              sm="8"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Address"
                rules="required"
              >
                <v-text-field
                  v-model="parent.billingAddress"
                  outlined
                  :disabled="billingCheckbox"
                  :error-messages="errors"
                  :label="$t('caTerm.basicInfo.address')"
                  data-testid="billing-address"
                  @keyup="validateField"
                  @click="
                    clickedFieldGlobal(
                      'billingAddress',
                      87,
                      'CA Term Survey v1'
                    )
                  "
                >
                </v-text-field>
              </validation-provider>
            </v-col>
            <v-col
              cols="11"
              sm="8"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                name="City"
                rules="required"
              >
                <v-text-field
                  v-model="parent.billingCity"
                  outlined
                  :disabled="billingCheckbox"
                  :error-messages="errors"
                  :label="$t('caTerm.basicInfo.city')"
                  data-testid="billing-city"
                  @keyup="validateField"
                  @click="
                    clickedFieldGlobal(
                      'billingCity',
                      88,
                      'CA Term Survey v1'
                    )
                  "
                >
                </v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="11"
              sm="8"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Postal Code"
                rules="required|postalCode"
              >
                <v-text-field
                  v-model="parent.billingPostalCode"
                  v-mask="'A#A #A#'"
                  :disabled="billingCheckbox"
                  outlined
                  :error-messages="errors"
                  data-testid="billing-postalcode"
                  :label="$t('caTerm.basicInfo.postalCode')"
                  @keyup="validateField"
                >
                </v-text-field>
              </validation-provider>
            </v-col>
            <v-col
              cols="11"
              sm="8"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Province"
                rules="required"
              >
                <v-select
                  v-model="parent.billingProvince"
                  :disabled="billingCheckbox"
                  :items="parent.PROVINCES"
                  item-text="name"
                  item-value="abbreviation"
                  data-testid="billing-province"
                  outlined
                  :error-messages="errors"
                  :label="$t('caTerm.basicInfo.province')"
                  @change="validateField"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="11"
              sm="8"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Phone"
                :rules="{ required: true,'caPhone':true}"
              >
                <v-text-field
                  v-model="parent.billingPhone"
                  v-mask="'(###)-###-####'"
                  :disabled="billingCheckbox"
                  data-testid="billing-phoneno"
                  outlined
                  :error-messages="errors"
                  :label="$t('caTerm.basicInfo.phoneNumber')"
                  hint="By providing your phone number, you may receive calls or texts about your policy and claims."
                  persistent-hint
                  @keyup="validateField"
                  @click="
                    clickedFieldGlobal(
                      'billingPhone',
                      91,
                      'CA Term Survey v1'
                    )
                  "
                >
                </v-text-field>
              </validation-provider>
            </v-col>
            <v-col
              cols="11"
              sm="8"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Email"
                rules="required|email"
              >
                <v-text-field
                  v-model="parent.billingEmail"
                  outlined
                  :disabled="billingCheckbox"
                  :error-messages="errors"
                  data-testid="billing-email"
                  :label="$t('caTerm.basicInfo.email')"
                  @keyup="validateField"
                  @click="
                    clickedFieldGlobal(
                      'billingEmail',
                      92,
                      'CA Term Survey v1'
                    )
                  "
                >
                </v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
        </v-form>
      </ValidationObserver>
    </div>
    <v-row class="d-flex justify-center">
      <v-col class="text-center text-h3 primary--text font-weight-bold final-premium">
        {{ $t('caTerm.payments.amountDue') }}: {{ finalPremium }}
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col
        class="text-center text-h3 primary--text font-weight-bold payment-frequency"
      >
        {{ $t('common.paymentFrequncey') }}:  {{ parent.paymentFrequency === 'monthly' ? $t("common.paidMonthly") : $t("common.paidAnnually") }}
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center text-center px-2">
      <v-col
        cols="11"
        class="d-flex justify-center align-center"
      >
        <p class="text-h4 secondary--text font-weight-bold mr-2">
          {{ $t('caTerm.payments.selectPaymentType') }}
        </p>
        <v-radio-group
          v-model="parent.paymethod"
          row
        >
          <v-radio
            v-for="(item) in parent.paymentOptions"
            :key="item.value"
            color="blanketbutton"
            text
            outlined
            :value="item.value"
            :name="item.value"
          >
            <template v-slot:label>
              <span class="radio-icon style-icon">
                <v-icon class="primary--text dash-icons">{{ item.icon }}</v-icon>
              </span>
              <span>{{ item.label }}</span>
            </template>
          </v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row
      v-if="parent.paymethod === 'creditcard'"
      class="d-flex justify-center"
    >
      <v-col
        cols="11"
        sm="6"
      >
        <b-credit-card
          :is-form-valid="isFormValid"
          :validate-form="validateField"
          @make-payment="updateCardDetails"
        />
      </v-col>
    </v-row>
    <v-row
      v-if="parent.paymethod === 'ach'"
      class="d-flex justify-center"
    >
      <v-col
        cols="11"
        sm="6"
      >
        <b-banking-form
          :payment-frequency="parent.paymentFrequency"
          :premium-amount="parent.paymentFrequency==='monthly'?parent.formattedMonthlyTotal:parent.formattedAnnualTotal"
          :is-form-valid="isFormValid"
          :validate-form="validateField"
          @make-bank-payment="makeBankPayment"
        />
      </v-col>
    </v-row>
    <v-row class="pa-2 ma-2 d-flex justify-center">
      <v-btn
        x-large
        class="white--text font-weight-bold style-back"
        :disabled="parent.loading"
        @click="parent.back"
      >
        {{ $t('common.buttons.back') }}
      </v-btn>
    </v-row>
    <v-row
      v-if="parent.time"
      class="mt-12"
    >
      <v-col class="d-flex justify-center">
        <ellipse-progress
          :loading="parent.time"
          :size="180"
          img-src="src/assets/images/termlife-icon.png"
          thickness="5"
          empty-thickness="3"
          line-mode="out 5"
          legend="false"
          animation="rs 700 1000"
          font-size="1.5rem"
          color="#00A1B7"
          empty-color-fill="transparent"
          filename="termlife-icon.png"
        >
        </ellipse-progress>
      </v-col>
    </v-row>
  </v-stepper-content>
</template>

<script>
  import i18n from '@/i18n'
  import { localCurrency } from '@/utils/common'
  import { formatCurrency } from '@/i18nValidate'
  import BCreditCard from '../../components/TermLifeCA/BCreditCard.vue'
  import BBankingForm from '../../components/TermLifeCA/BBankingForm.vue'
  import { validateACH, validateCC } from '../../../../plugins/chargeover'
  import { gtag_report_conversion } from '../../../../utils/common'
  import { toCamelCase } from '../../../../utils/normalizeToEnglish'
  export default {
    components: {
      BCreditCard,
      BBankingForm,
    },
    props: ['parent'],
    data: () => ({
      billingCheckbox: false,
      isFormValid: false
    }),
    computed: {
      finalPremium () {
        return this.parent.paymentFrequency === 'monthly' ? this.parent.formattedMonthlyTotal : this.parent.formattedAnnualTotal
      },
      currentAddress () {
        return this.$store.getters.getCurrentAddress
      }
    },
    watch: {
      billingCheckbox (val) {
        if (val) {
          this.copyAddress()
          this.isFormValid = true
        } else {
          this.resetData()
          this.isFormValid = false
          this.copyPartialFields()
        }
      },
      currentAddress (val) {
        if (val && val?.Text) {
          this.copyPartialFields()
        }
      },
      'parent.user': {
        handler (newUser) {
          this.copyPartialFields()
        },
        deep: true
      }
    },
    mounted () {
      this.copyPartialFields()
    },
    methods: {
      copyAddress () {
        this.parent.billingFirstName = this.parent.user.firstName
        this.parent.billingLastName = this.parent.user.lastName
        this.parent.billingAddress = this.parent.user.address.addr1
        this.parent.billingCity = this.parent.user.address.city
        this.parent.billingProvince = this.parent.user.address.province
        this.parent.billingPostalCode = this.parent.user.address.postalCode
        this.parent.billingPhone = this.parent.user.phoneNumber
        this.parent.billingEmail = this.parent.user.emailAddress
      },
      resetData () {
        this.parent.billingFirstName = ''
        this.parent.billingLastName = ''
        this.parent.billingAddress = ''
        this.parent.billingCity = ''
        this.parent.billingProvince = ''
        this.parent.billingPostalCode = ''
      },
      copyPartialFields () {
        this.parent.billingPhone = this.parent.user.phoneNumber
        this.parent.billingEmail = this.parent.user.emailAddress
      },
      async navigateToPayment () {
        try {
          const form4Result = await this.$refs.form4.validate()

          if (!form4Result) {
            console.log('Validation failed.')
            return
          }

          this.parent.applicationStep = 7
        } catch (error) {
          console.error('Error in changing steps', error)
        }
      },
      localCurrency,
      formatCurrency,
      async makeBankPayment (data) {
        this.parent.bankDetails = data
        const { isValid, response, message } = await validateACH({ routing: data.routing, number: data.number, })
        if (isValid) {
          this.parent.paymentMethod.error = false
          this.parent.paymentMethod.message = null
          await this.makeCATermACHPayment()
        } else {
          this.parent.paymentMethod.error = true
          this.parent.paymentMethod.message = message
        }
      },
      async makeCATermACHPayment () {
        try {
          await this.$store.dispatch('makeCATermACHPayment', {
            createCustomerPayload: this.parent.createCustomerPayload,
            storeCATermACHPayload: this.parent.bankDetails,
            subscribeCATermPayload: this.parent.subscribeCATermPayload,
            bindPolicyPayload: this.parent.bindPolicyPayload
          })
          if (!this.parent.error && this.$store.getters.getCATermPaymentSuccessful) {
            this.parent.bindPolicyPayload.bankName = this?.parent.bankDetails?.bank ?? ''
            this.parent.bindPolicyPayload.accountNumber = parseInt(this?.parent.bankDetails?.number?.toString()?.slice(-4)) ?? ''
            this.parent.bindPolicyPayload.accountType = this?.parent.bankDetails?.type ?? ''
            await this.$store.dispatch('CATermBindPolicy', this.parent.bindPolicyPayload)
            if (!this.parent.error) {
              await this.$store.dispatch(
                'sendCATermPolicyPdfWithTemplateId',
                {
                  firstname: this.parent.user.firstName,
                  partnerId: this.parent.partnerID
                }
              )
              this.parent.applicationStep++
              gtag_report_conversion(this.$gtag, process.env.VUE_APP_PAYMENT)
              this.$store.commit('setStateProperty', { property: 'CATermCustomerSubscriptionId', value: null })
              this.$store.commit('setStateProperty', { property: 'CATermCustomerId', value: null })
              this.parent.isPolicyPuchased = true
            }
          }
        } catch (error) {
          console.error(error)
        }
      },
      async updateCardDetails (data) {
        this.parent.cardDetails = data
        const { isValid, response, message } = await validateCC({ ...data })
        if (isValid) {
          this.parent.paymentMethod.error = false
          this.parent.paymentMethod.message = null
          await this.makeCATermCreditCardPayment()
        } else {
          this.parent.paymentMethod.error = true
          this.parent.paymentMethod.message = message
        }
      },
      async makeCATermCreditCardPayment () {
        try {
          await this.$store.dispatch('makeCATermCreditCardPayment', {
            createCustomerPayload: this.parent.createCustomerPayload,
            storeCATermCreditCardPayload: this.parent.storeCATermCreditCardPayload,
            subscribeCATermPayload: this.parent.subscribeCATermPayload,
            bindPolicyPayload: this.parent.bindPolicyPayload
          })

          if (!this.parent.error && this.$store.getters.getCATermPaymentSuccessful) {
            await this.$store.dispatch('CATermBindPolicy', this.parent.bindPolicyPayload)
            if (!this.parent.error) {
              await this.$store.dispatch(
                'sendCATermPolicyPdfWithTemplateId',
                {
                  firstname: this.parent.user.firstName,
                  partnerId: this.parent.partnerID
                }
              )
              this.parent.applicationStep++
              this.parent.isPolicyPuchased = true
              gtag_report_conversion(this.$gtag, process.env.VUE_APP_PAYMENT)
            }
          }
        } catch (error) {
          console.error(error)
        }
      },
      async validateField () {
        try {
          const [observer1Result, formResult] = await Promise.all([
            this.$refs.billingInfoObserver.validate(),
            this.$refs.billingForm.validate()
          ])
          if (!observer1Result || !formResult) {
            console.log('Validation failed.')
            this.isFormValid = false
            return
          } else {
            this.isFormValid = true
          }
        } catch (error) {
          console.error('Error in validating:', error)
        }
      }
    }
  }
</script>
<style scoped>
#dashboard{
  display: none;
}
@media (max-width: 768px) {
.billing-container {
  padding: 8px;
}
}
::v-deep .billing-container-checkbox label {
  font-size: 20px;
}
.billing-container {
  margin-bottom: 30px;
}
</style>
